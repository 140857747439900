@import "../../global.scss";

.servicios {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 5% 0 5% 0;
  
  @include laptop{
    margin-bottom: 1%;
  }
  @include mobile{
    margin-bottom: 1%;
  }

  h2 {
    font-size: 50px;
    margin-bottom: 2%;
    @include mobile{
      font-size: 20px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile{
      width: 95%;
    }

    .item {
      width: 350px;
      border: 1px solid rgb(240, 239, 239);
      margin: 2px 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;
      overflow-y: hidden;

      @include mobile{
        width: 400px;
        height: 120px;
      }

      h3 {
        position: absolute;
        font-size: 20px;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }
  }
}

div#servicios {
  height: auto;
}