@import "../../global.scss";

.informacion {
  background-color: black;
  display: flex;
  height: 360px !important;
  @include mobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 230px !important;
  }

  .right {
    flex: 7;
    position: relative;

    @include smallTablet {
      flex: unset;
    }
    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include laptop {
        padding-left: 0;
      }
      @include mobile {
        padding-left: 0;
        align-items: center;
      }
      p {
        font-size: 25px;
        color: white;
        text-align: center;
        margin-left: 300px;
        margin-right: 320px;
        @include laptop {
          margin-left: 50px;
          margin-right: 50px;
          font-size: 20px;
        }

        @include mobile {
          font-size: 20px;
          margin: 0;
          width: 90%;
        }
        @include mediumMobile {
          font-size: 16px;
        }
        @include smallLaptop {
          font-size: 20px;
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
