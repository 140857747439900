@import "../../global.scss";

.segmentos {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100% !important;
  min-height: 100vh;
  padding: 43px 0;

  * {
    overflow: hidden;
    transition: all 0.3s;
    text-decoration: none;
    svg *{
      transition: all 60ms;
    }
  }
  a {
    color: inherit;
  }

  @include mobile {
    justify-content: space-around;
    height: 100%;
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  h1 {
    color: black;
    margin-top: 40px;
    font-size: 40px;
    padding: 10px;
    @include laptop {
      font-size: 35px;
    }
    @include smallLaptop {
      font-size: 25px;
    }
    @include mobile {
      font-size: 18px;
    }
    @include mediumMobile {
      text-align: center;
    }
  }

  h2 {
    color: black;
    margin-top: 40px;
    font-size: 40px;
    padding: 10px;
    @include laptop {
      font-size: 35px;
    }
    @include smallLaptop {
      font-size: 25px;
    }
    @include mobile {
      font-size: 18px;
    }
    @include mediumMobile {
      text-align: center;
    }
  }

  .container {
    width: 100%;
   // height: 54%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 30px;
    max-width: 1272px;
    overflow: unset;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    * {
      overflow: unset;
    }
    @include laptop {
      gap: 0;
    }
    @include mobile {
      width: 100%;
      height: 100%;
      justify-content: center;
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      gap: 0 50px;
    }

    .card {
      width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    //  transition: all 0.6s ease;
      
      text-align: center;
      &:hover {
        .top h3 {
          transition: all 0.6s;
          background-color: #000;
          color: #fff !important;
        }
        .requisitosImagen *{
          transition: all 0.6s;
          fill: #000;
          
        }
        #Capa_2{
          transition: all 0.6s;
          fill: #000;
        }
      }
      @include mobile {
        width: 100%;
        height: 100%;
      }

      &.featured {
        width: 333px;
        margin: 0 20px;
        padding: 15px 0;
        box-sizing: border-box;
        @include mobile {
          width: 320px;
          margin: 8px 0;
        }
        // @include mobile {
        //   width: 70%;
        //   margin: 8px 0;
        // }
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        gap: 15px;
        h3 {
          background-color: transparent;
          color: #000 !important;
          border: 1px solid;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 !important;
          padding: 0 !important;
          height: 35px;

          @include laptop {
            max-width: 400px;
          }
          @include smallLaptop {
            font-size: 20px;
          }
          @include mobile {
            font-size: 18px;
          }
        }

        .requisitosImagen {
          //height: 300px;
          transition: all 0.6s;
          //object-fit: cover;
          margin: 0 30px;

          @include smallMobile {
            //  width: 80px;
            height: 100%;
          }
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 66px;
        max-height: 100px;
        box-sizing: border-box;

        .textoRequisitos {
          margin-top: 20px;
        }

        @include smallLaptop {
          padding: 0 12px;
        }
        @include mobile {
          width: 100%;
          height: 100%;
          align-items: start;
        }
        p {
          margin-top: 5px;
          font-size: 14px;
          text-align: center;
          height: 100%;
          @include smallLaptop {
            font-size: 12px;
          }
          @include mobile {
            font-size: 12px;
          }
          @include mediumMobile {
            font-size: 13px;
          }
        }
      }
    }
  }

  .top-img-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 130px;
    @include mobile{
      height: 80px;
    }
    svg {
      height: 100%;
      fill: dimgrey;
    }
  }
  h3 {
    overflow: unset !important;
    color: white;
    @include laptop {
      font-size: 20px;
      color: white;
    }
    @include smallLaptop {
      font-size: 17px;
      max-width: 900px;
      text-align: center;
      margin: 0 10px;
    }
    @include mediumMobile {
      font-size: 14px;
      max-width: 900px;
      text-align: center;
      margin: 0 10px;
    }
  }
  .top-txt-container {
    width: 100%;
    align-items: center;
    justify-content: center;

    h3 {
      padding: 3px;
      color: white;
      background-color: black;

      @include mobile {
        color: white;
      }
    }
  }
  .arrow {
    width: 100px;
    @include smallTablet {
      width: 60px;
    }
  }
  .arrow-container {
    width: 100%;
    color: #000000;
    img {
      width: 100%;
    }
    transition: all 0.3s;
    @include mobile {
      transform: rotate(90deg);
    }
  }
}
