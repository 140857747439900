@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./global.scss";

/* Para Chrome y Opera */
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: auto;
}

/* Para Firefox */

*{
   scrollbar-width: none;
 
}
img{
  overflow: hidden;
}
.app {
  height: 100vh;
  * {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
  }
  .sections {
    width: 100%;
    background-color: #EFEFEF;
    position: relative;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    scrollbar-width: none; //for firefox
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: 100vw;
      scroll-snap-align: start;
      height: 80vh;
      overflow: hidden;
      @include mobile{
        height: 60vh;
      }
      
    }
  }
}

@font-face {
  font-family: Helvetica;
  src: url(../public/assets/Helvetica.ttc);
}

.helvetica {
  font-family: Helvetica;
}

@font-face {
  font-family: ITCAvantGardeStd-Bold;
  src: url(../public/assets/ITCAvantGardeStd-Bold.ttf);
}

.ITBold {
  font-family: ITCAvantGardeStd-Bold;
}

@font-face {
  font-family: ITCAvantGardeStd-Bk;
  src: url(../public/assets/ITCAvantGardeStd-Bk.ttf);
}

.ITBk {
  font-family: ITCAvantGardeStd-Bk;
  color: #202029;
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;

  @media (prefers-reduced-motion) {
    .hidden {
      transition: none;
    }
  }
}

.show {
  opacity: 1 !important;
}

@keyframes fadeIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
    transition: all 1s ease;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    transform: translateY(30px);
    transition: all 1s ease;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  animation-name: fadeIn;
  transition: all 1s linear;
  animation-duration: 1s; /* Duración de la animación. Ajusta según necesites. */
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.fade-out {
  transition: all 0.6s ease-out;
}
.delay1 {
  animation-delay: 0.5s;
}
.delay2 {
  animation-delay: 2s;
}
.tset {
}

.whatsapp-icon {
  position: fixed;
  width: 50px;
  height: 90;
  bottom: 20px;
  right: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}


/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      height: 40px;
      width: 40px;
      margin-top: 50px;
      margin-bottom: 40px;
  }

  .button_float {
      width: 200px;
      height: 90px;
      bottom: 0px;
      right: 0px;
      font-size: 22px;
  }
}