@import "../../global.scss";

.topbar {
  width: 100%;
  height: 70px;
  //background-color: rgba(154, 154, 154);
  background-color: rgb(227 227 227);
 // opacity: .8;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
  @include smallTablet{
    background-color: rgb(227 227 227);
    opacity: 1;
  }
  a{
    text-decoration: none;
  }

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    @include mobile{
        justify-content: space-between;
    }

    .left {
      display: flex;
      align-items: center;
     
      .logo {
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
        width: 180px;
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;

        @include mobile{
          display: none;
        }

        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .right {
      display:none;
      @include mobile{
        display:flex;
        gap: 10px;
        color: #000;
      }
      .hamburger {
        @include mobile{
          width: 32px;
          height: 25px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;

          }
        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          @include mobile{
            background-color: #000;
          }
          transform-origin: left;
          transition: all 2s ease;
          @include mobile{
            color: #000;
          }
        }
      }
    }
  }

  &.active {
   // background-color: $mainColor;
    color: #000;

    .hamburger {
      
      span {
        &:first-child {
          background-color: #000;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: #000;
          transform: rotate(-45deg);
        }
      }
    }
  }
}