@import "../../global.scss";

#video{
  width: 100vw;
  height: 100vh;
  object-fit: cover;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  @include mobile{
    display: none;
  }
}



  
