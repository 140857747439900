$mainColor: #fff;
$secondaryColor: rgb(215 215 215);

$desktop: 1920;
$smallDesktop: 1680;
$laptop: 1366;
$smallLaptop: 1200;
$tablet: 1024;
$smallTablet: 900;
$mobile: 768;
$mediumMobile: 600;
$smallMobile: 480;
$ultraSmallMobile: 420;
$ultraSmallMobile2: 400;
@mixin desktop {
  @media (max-width: #{$desktop}px) {
    @content;
  }
}
@mixin smallDesktop {
  @media (max-width: #{$smallDesktop}px) {
    @content;
  }
}
@mixin laptop {
  @media (max-width: #{$laptop}px) {
    @content;
  }
}
@mixin smallLaptop {
  @media (max-width: #{$smallLaptop}px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$tablet}px) {
    @content;
  }
}
@mixin smallTablet {
  @media (max-width: #{$smallTablet}px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobile}px) {
    @content;
  }
}
@mixin mediumMobile {
  @media (max-width: #{$mediumMobile}px) {
    @content;
  }
}
@mixin smallMobile {
  @media (max-width: #{$smallMobile}px) {
    @content;
  }
}
@mixin ultraSmallMobile {
  @media (max-width: #{$ultraSmallMobile}px) {
    @content;
  }
}
@mixin ultraSmallMobile2 {
  @media (max-width: #{$ultraSmallMobile2}px) {
    @content;
  }
}
