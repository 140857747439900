@import "../../global.scss";

.impresiones {
  display: flex;
  justify-content: left;
  align-items: center;
  
  background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-image: url("./equipo-impresora.jpg");
  

  @include mobile{
    
    justify-content: center;
    align-items: center;

  }

  @include mediumMobile{
    *{
      font-size: 0.9em;

    }

  }



  .wrapper {
    width: 100%;
    height: 100%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    

    @include mobile{
    //  height: 100vh;
      background-position: left;
      background-image: url("./equipo-impresora.jpg");
    }

      h1 {
        font-size: 25px;
        margin: 10px 0;
        color: white;
        
        @include mobile{
          font-size: 40px;
        }
      }
      h2 {
        
        font-size: 25px;
        color: white;
        width: 100%;
        max-width: 385px;
        min-height: 219px;
        line-height: 33px;
        box-sizing: border-box;
        padding: 10px 25px;
        background: rgba(0,0,0,0.3);
        font-weight: lighter;
        @include mediumMobile{
          max-width: 100%;
        }
      }
      h3 {
        font-size: 25px;

        @include mobile{
          font-size: 20px;
        }

        span {
          font-size: inherit;
          color: crimson;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }

