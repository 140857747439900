@import "../../global.scss";

.clientes {
  height: calc(100vh - 70px);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    padding: 20px;
    font-size: 50px;
    color: transparent;
    -webkit-text-stroke: 1px black;

    @include mobile {
      font-size: 25px;
      -webkit-text-stroke: 0.5px black;
    }
  }
  b {
    color: black;
  }

  h3 {
    margin-left: 30%;
    margin-right: 30%;
    font-weight: 100;
    text-align: center;

    @include mobile {
      width: 80%;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile {
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .txt-more-clients {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      -webkit-text-stroke: 1px transparent;
      color: black;
      font-size: 1.5em;
    }
  }

  .container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      width: 100%;
    }

    .item {
      width: 650px;
      height: 350px;
      margin: 2px 2px;
      display: flex;
      margin-top: 20px;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all 0.5s ease;
      cursor: pointer;

      @include mobile {
      }

      h3 {
        position: absolute;
        font-size: 20px;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }

      .imgClientes {
        width: 100%;
        height: 100%;

        @include mobile {
          width: 350px;
          height: 230px;
        }
      }
    }
  }
}
