@import "../../global.scss";

.into-txt {
  max-width: 46%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: end;
  width: 100%;
}
.intro {
  display: flex;
  height: 63vh !important;

  @include mobile{
    flex-direction: column;
    align-items: center;
    height:  auto !important;
  }


    .wrapper {
      width: 100%;
      //padding-left: 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;

      background-image: url("./banneretimex.png");
      background-position:center;
      align-items: end;

      @include mobile{
        height: 250px;
        padding-left: 0;
        align-items: center;
        position: relative;
        background-size: cover;
        background-position: center;

        background-image: url("./banneretimex.png");

      }

      h1 {
        font-size: 25px;
        margin: 10px 0;
        color: white;

        @include mobile{
          font-size: 40px;
        }
      }
      p {
        font-size: 20px;
        color: white;
        width: 60%;
        font-weight: lighter;
        @include smallTablet{
          width: 80%;
        }
        @include mobile{
          font-size: 18px;
        }
        @include mediumMobile{
          width: 100%;
          
        }
        @include smallMobile{
          font-size: 13px;
        }
      }
      h3 {
        font-size: 25px;

        @include mobile{
          font-size: 20px;
        }

        span {
          font-size: inherit;
          color: crimson;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }

