@import "../../global.scss";

.print {
  display: flex;
  justify-content: left;
  align-items: center;

  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("./print-connect.png");

  @include mobile {
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    width: 100%;
    height: 100vh;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    @include mobile {
      height: 100vh;
      background-image: url("./print-responsive.png");
      background-repeat: no-repeat;
      background-position: left;
      
    }

    h2 {
      font-size: 70px;
      padding-left: 20%;
      color: transparent;
      -webkit-text-stroke: 1px black;

      @include mobile {
        font-size: 50px;
        text-align: left;
        padding-left: 20px;
        -webkit-text-stroke: 0.5px black;
      }
    }

    h3 {
      font-size: 70px;
      margin-left: 30%;

      @include mobile {
        font-size: 50px;
        margin-left: 10%;
      }
    }

    h4 {
      font-size: 20px;
      margin-left: 20%;
      width: 30%;

      @include mobile {
        font-size: 15px;
        width: 80%;
        text-align: left;
        margin-left: 20px;
        text-shadow: 0px 2px 0px #ffffffdb;
      }
      @include mediumMobile {
        font-size: 12px;
      }
    }
  }

  a {
    position: absolute;
    bottom: 10px;
    left: 40%;

    img {
      width: 30px;
      animation: arrowBlink 2s infinite;
    }

    @keyframes arrowBlink {
      100% {
        opacity: 0;
      }
    }
  }
}
