@import "../../global.scss";

.works {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  *{
    overflow: unset;
  }
  @include mobile {
    height: 30vh !important;
  }
  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;

    @include mobile {
      //   display: none;
      //right: 0;
      height: 20px;
    }

    &.right {
      right: 100px;
      @include mobile {
        //   display: none;
        right: 5px;
      }
    }

    &.left {
      left: 100px;
      transform: rotateY(180deg);
      @include mobile {
        //   display: none;
        left: 5px;
      }
    }
  }
 
  .slider {
    height: 550px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @include mobile {
      height: auto !important;
      //flex-direction: column;
      justify-content: center;
      // display: none;
      // & > .container {
      //   display: none !important;
      // }
      // & > .container:nth-child(1) {
      //   display: flex !important;

      // }
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 100%;
        height: 100%;
        background-color: white;
        //border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 100%;
          height: 250px;
          margin: 15px 0;
        }

        .right {
          flex: 4;
          //height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .rightContainer {
            width: 100%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            h2 {
              font-size: 40px;

              @include mobile {
                font-size: 13px;
              }
            }
            p {
              font-size: 25px;
              padding-right: 160px;
              @include mobile {
                font-size: 12px;
                padding-right: 10px;
              }
            }
            span {
              font-size: 12px;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .left {
          flex: 5;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          position: relative;

          img {
         //   width: 200px;
            transform: rotate(-30deg);
          
            @include mobile {
              width: auto;
              height: 60%;
            }
            @include mediumMobile {
              width: auto;
              height: 60%;
            }
          }
        }
      }
      .item-3 {
        width: 100%;
        height: 100%;
        background-color: white;
        //border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 100%;
          //height: 250px;
          margin: 15px 0;
        }

        .right {
          flex: 4;
          //height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .rightContainer {
            width: 100%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            h2 {
              font-size: 40px;

              @include mobile {
                font-size: 13px;
              }
            }
            p {
              font-size: 25px;
              padding-right: 160px;
              @include mobile {
                font-size: 12px;
                padding-right: 10px;
              }
            }
            span {
              font-size: 12px;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
       
        .imgdiagonal1{
          max-width: 250px;
          @include smallDesktop {
            max-width: 200px;
          }
          @include tablet {
            left: 15% !important;
          }
          @include mobile {
            left: 20% !important;
          }
          @include smallMobile {
            left: 15% !important;
          }
        }
        .imgdiagonal2{
          max-width: 250px;
          @include smallDesktop {
            max-width: 200px;
          }
        }
        .left {
          flex: 5;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          position: relative;

          img {
            
            transform: rotate(-30deg);
          
            @include mobile {
              width: 80px;
            //  height: 60%;
            }
            @include mediumMobile {
              width: auto;
              height: 60%;
            }
          }
         
         
        }
      }
     
    }
  }
  .slider1-h{
    width: 350px;
    @include mobile { 
      width: 350px;


    }
  }
  .slider2-h{
    height: 100% !important;
    max-height: 600px;
    min-width: 200px;
    width: auto !important;
    @include mobile {      
      height: 60% !important;
    }
  }
 
}
